<template>
  <div class="notice">
     <top-bar :title="'工作目标'" :left="true"></top-bar>
    <div class="workGoal">
      <work-item
        title="近期工作"
        titlEng="recent work"
        :imgurl="require('@/assets/img/recent.png')"
        style="
          background-image: linear-gradient(to right, #9487fd, #7a68f2);
          border-radius: 10px;
        "
        @click.native="workTargetList(0, '近期工作')"
      />
      <work-item
        title="今年工作"
        titlEng="Work this year"
        :imgurl="require('@/assets/img/oneYear.png')"
        style="
          background-image: linear-gradient(to right, #fd7b79, #f64744);
          border-radius: 10px;
        "
        category="1"
        @click.native="workTargetList(1, '今年工作')"
      />
      <work-item
        title="五年工作"
        titlEng="Five year of work"
        :imgurl="require('@/assets/img/fiveYears.png')"
        style="
          background-image: linear-gradient(to right, #6fa4ff, #367ef4);
          border-radius: 10px;
        "
        @click.native="workTargetList(2, '五年工作')"
      />
    </div>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
import workItem from "./workItem.vue";

export default {
  components: {
    topBar,
    workItem,
  },
  created(){
    this.getOpenId()
  },
  methods: {
    getOpenId(){
      this.$http({
        url: this.$http.adornUrl("/wxapp/getOpenId"),
        method: "post",
        params: this.$http.adornParams(),
      }).then(
        ({ data }) => {
          if (data.code == 0) {
            this.recentWorkDate = data.page.list;
            // 加载状态结束
            this.loading = false;
            this.$toast.clear();
          }
          this.$toast.clear();
        },
        (err) => {
          this.$toast.clear();
        }
      );
    },
    workTargetList(category, title) {
      this.$router.push({
        name: "gridRecentWork",
        query: { title: title, category: category },
      });
    },
  },
};
</script>

<style scoped>
.notice {
  width: 100%;
  height: 100vh;
   box-sizing: border-box;
   overflow: hidden;
   background-color: #f6f5f3;
}
.workGoal {
 width: 100vw;
  height: 100%;
  background-color: #f6f5f3;
  /* margin-top: 150px; */
}
</style>
